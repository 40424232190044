@import '../../../styles/app/common/variables.scss';

#detailModal {
    min-width: 1000px;

    .MuiTabs-flexContainer {
        gap: 10px;
    }

    .dx-datagrid-search-panel.dx-texteditor.dx-editor-underlined:after {
        border-bottom: none;
    }

    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .MuiDialog-paper {
        max-width: 1230px;
    }

    .MuiDialogContent-root {
        min-height: 60vh;

        .MuiDialogActions-root {
            padding: 8px 0;
            margin-right: -10px;
        }
    }

    .MuiDialogTitle-root {
        background-color: $blue-dark;

        &.modalDetail {
            background-color: $gray-med-dark;
        }

        color: $white;

        .detail-modal {
            &--title {
                flex-grow: 1;
                text-align: center;
            }

            &--dim {
                color: $gray-med-dark;
            }
        }

        .MuiSvgIcon-root {
            color: $white;
            padding: 0;
        }

        .MuiIconButton-root {
            padding: 0;
        }

        .MuiSvgIcon-root {
            font-size: 1.75rem;
        }
    }
}

.statutory-template-document-links {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}