.generalActionFormWrapper {
    margin-top: 1rem;
    padding: 1rem;

    .generalActionFormGrid {
        display: grid;
        //grid-template-columns: auto auto auto auto auto auto;
        grid-template-columns: repeat(12, minmax(0, 1fr)); //minmax(0, 1fr)
        grid-gap: 20px;
    }


    span.actionFormCaption {
        margin-bottom: 1rem;
        display: block;
    }
}

.generalActionFormWrapper {

    .generalActionFormGrid.fieldGroupsForm {

        .MuiTypography-body1 {
            font-size: unset;
        }

        display: flex;
        flex-direction: column;
        grid-gap: unset;

        .MuiFormControlLabel-labelPlacementTop {
            // these will usually be within .field-group-wrappers, but we may as well keep it more general
            margin-left: 0px;
            flex-direction: row-reverse;
            justify-content: start;
        }

        .dx-datagrid {
            height: auto;
        }

        .field-group-wrapper {
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            grid-gap: 20px;
            margin-bottom: 2rem;

            h3 {
                grid-column: span 12;
                grid-row: 1;
                font-size: 1.2rem;
            }

            h4 {
                grid-column: span 12;
                grid-row: 2;
                font-size: 1rem;
            }

            .dx-datagrid {
                height: auto;
            }
        }

        .field-group-wrapper.parent {
            display: block; // this is so that by default 'groups' that directly contain a child group of fields are not themselves grids - the group of children

            // fields are shown in a grid
            .field-group-wrapper {
                padding-left: 2rem;
                margin-top: 2rem;
            }

        }
    }
}

.generalActionFormWrapper {
    .generalActionFormGrid.reactiveCategoryRowIdentifierForm {
        .input-wrapper--contract {
            grid-column: span 3; //1/ span 3;
            grid-row: 1;
        }

        .input-wrapper--issue_category {
            grid-column: span 3; //5/ span 3;
            grid-row: 1;
        }
    }

    .generalActionFormGrid.reactiveCategoryInitialValueForm {
        .input-wrapper--month {
            grid-column: span 3; //1/ span 3;
            grid-row: 1;
        }

        .input-wrapper--year {
            grid-column: span 3; //5/ span 3;
            grid-row: 1;
        }

        .input-wrapper--value {
            grid-column: span 3; //9/ span 3;
            grid-row: 1;
        }
    }
}

.generalActionFormWrapper {
    .generalActionFormGrid.visitEditForm {

        .input-wrapper--service_type,
        .input-wrapper--check_type {
            grid-column: span 12;
            grid-row: 1;
        }

        .input-wrapper--scheduled_for {
            grid-column: span 12;
            grid-row: 2;
        }

        .input-wrapper--status {
            grid-column: span 12;
            grid-row: 3;
        }

        .input-wrapper--booked {
            grid-column: span 4;
            grid-row: 4;

            .MuiFormControlLabel-root {
                align-items: start;
                margin-left: 0px
            }

            .MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root {
                margin: 2px;
                margin-left: -2px;
                padding: 0;

                .MuiIconButton-label {
                    justify-content: start;
                }
            }

            .MuiFormControlLabel-labelPlacementTop {
                margin-left: 0;
            }



            // label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop {
            //     margin-left: 0;
            // }
        }



        .input-wrapper--notes {
            grid-column: span 12; //5/ span 3;
            grid-row: 5/ span 3;
        }
    }
}

.generalActionFormWrapper {
    .generalActionFormGrid.HASTaskAdminEditForm {
        .input-wrapper--statutory_doc_ref {
            grid-column: span 12;
            grid-row: 1;
        }

        .input-wrapper--guidance {
            grid-column: span 12;
            grid-row: 2;
        }
    }
}

.generalActionFormWrapper {
    overflow: auto;

    .generalActionFormGrid.HASTaskEditForm {

        .input-wrapper--name {
            grid-column: span 12;
            grid-row: 1;
        }

        .input-wrapper--statutory_doc_ref {
            grid-column: span 12;
            grid-row: 2;
        }

        .input-wrapper--guidance {
            grid-column: span 12;
            grid-row: 3;
        }

        .input-wrapper--interval {
            grid-column: span 5;
            grid-row: 4;
        }

        .input-wrapper--interval_unit {
            grid-column: span 7;
            grid-row: 4;
        }

        .input-wrapper--applicable_from {
            grid-column: span 12;
            grid-row: 5;
        }

        .input-wrapper--grace_period {
            grid-column: span 12;
            grid-row: 7;
        }

        .input-wrapper--for_contract {
            grid-column: span 12;
            grid-row: 8;
        }

    }
}

.generalActionFormWrapper {
    .generalActionFormGrid.HASCheckEditForm {

        .input-wrapper--check_type {
            grid-column: span 12;
            grid-row: 1;
        }

        .input-wrapper--scheduled_for {
            grid-column: span 12;
            grid-row: 2;
        }

        .input-wrapper--status {
            grid-column: span 12;
            grid-row: 3;
        }

        .input-wrapper--completed_on {
            grid-column: span 12;
            grid-row: 4;
        }

        .input-wrapper--notes {
            grid-column: span 12; //5/ span 3;
            grid-row: 5/ span 3;
        }
    }
}

.darker-disabled .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.62);
}

.darker-disabled .MuiFormControlLabel-label.Mui-disabled {
    color: rgba(0, 0, 0, 0.62);
}

.darker-disabled .dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
    opacity: 1;
    color: rgba(0, 0, 0, 0.62);

    .dx-texteditor-input {
        color: rgba(0, 0, 0, 0.62);
    }
}

.witnessStatementsWrapper,
.checkListDocumentsWrapper {
    padding: 0.5rem;
}

.witnessDocumentsBody {
    grid-column: span 12;
}

#AccidentWitnessStatementUploader {
    .autoField-input-wrapper {
        padding: 0.5rem;
    }

    .input-wrapper--name {
        grid-column: span 3;
        grid-row: 1;
    }

    .input-wrapper--address {
        grid-column: span 5;
        grid-row: 1;
    }

    .input-wrapper--telephone {
        grid-column: span 4;
        grid-row: 1;
    }

    .input-wrapper--internal_access_only {
        grid-column: span 3;
        grid-row: 3;
    }

    .input-wrapper--h_a_s_department_only {
        grid-column: span 3;
        grid-row: 3;
    }

    .filepond--wrapper {
        grid-column: span 12;
        grid-row: 4;
    }
}

#CheckListDocumentsUploader {
    .autoField-input-wrapper {
        padding: 0.5rem;
    }

    .input-wrapper--notes {
        grid-column: span 10;
        grid-row: 1;
    }

    .input-wrapper--h_a_s_department_only {
        grid-column: span 2;
        grid-row: 1;
    }

    .filepond--wrapper {
        grid-column: span 12;
        //grid-row: 3;
    }
}