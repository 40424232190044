#contractReportVisibility {
  .visibility-dialog {
    &__content {
      display: flex;
    }
  }
}

#contractReportAppendices {
  .MuiPaper-root {
    min-height: 400px;
  }
}

#reportDistributionListManager {


  .MuiDialogContent-root {
    background-color: aliceblue;
  }

  .reportDistributionList-dialog__content {
    background-color: white;
    padding: 1rem;
  }

  .distributionListContainer {
    margin-top: 1rem;

    .dx-toolbar-after {
      width: 100%;
      padding-left: 0;
    }

    .dx-datagrid-search-panel {
      width: 100%;
    }

    .dx-texteditor.dx-editor-underlined:after {
      border-bottom: none;
    }

    .dx-datagrid-header-panel {
      padding-left: 0;
    }
  }
}

.gridHeader {
  width: 100%;
  text-align: center;
  padding: 0.5rem;

  h1 {
    font-size: 1.2rem;
  }

}

.publicationFormWrapper {
  margin-top: 1rem;
  padding: 1rem;
}