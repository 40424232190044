@import '../../../../../styles/app/common/variables.scss';

.mat-confirmation-dialog {
  .MuiButton-label {
    color: $dynamic-blue;
  }
}

#updatePersonalContractInFocusSettings {
  .modal-title {
    text-align: center;
    width: 100%;
  }
  .generalPersonalContractSettingsFormGuideText {
    font-style: italic;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
  .MuiButtonBase-root {
    padding-bottom: 0;
    padding-top: 0;
  }
  h5 {
    padding-left: 0.5rem;
  }
  .MuiSvgIcon-root{
    fill: $blue;
  }
}

